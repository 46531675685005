/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W sierpniu 2024 roku została podpisana umowa na zadanie inwestycyjne pn. „Przebudowa drogi wew. zlokalizowanej na dz. nr 169/1 i 169/2 w m. Jaksice”."), "\n", React.createElement(_components.p, null, "Celem inwestycji była budowa drogi gminnej wewnętrznej komunikującej pobliskich mieszkańców drogą powiatową nr 2509C oraz drogą gminną 150503C."), "\n", React.createElement(_components.p, null, "W ramach prac budowlanych zostały zrealizowane między innymi następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "wykonanie drogi gminnej o nawierzchni bitumicznej,"), "\n", React.createElement(_components.li, null, "wykonanie poboczy o nawierzchni z kruszywa,"), "\n", React.createElement(_components.li, null, "przebudowę istniejących zjazdów indywidualnych do przyległych nieruchomości,"), "\n", React.createElement(_components.li, null, "przebudowę zjazdu publicznego,"), "\n", React.createElement(_components.li, null, "wykonanie humusowania terenów przyległych do inwestycji,"), "\n", React.createElement(_components.li, null, "wykonanie oznakowania omawianego odcinka,"), "\n", React.createElement(_components.li, null, "oczyszczenie pasa drogowego z kolidującego zadrzewienia oraz krzewów,"), "\n", React.createElement(_components.li, null, "wykonanie rur osłonowych dla zabezpieczenia istniejącej infrastruktury podziemnej,"), "\n", React.createElement(_components.li, null, "remont warstwy ścieralnej i warstwy wiążącej drogi powiatowej na długości włączenia drogi wewnętrznej,"), "\n", React.createElement(_components.li, null, "remont istniejącego przepustu pod koroną drogi polegający na wymianie istniejącej rury przepustowej."), "\n"), "\n", React.createElement(_components.p, null, "Inwestycja planowo została oddana do użytku w październiku 2024."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
